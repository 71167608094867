import Vue from 'vue'
import App from './App.vue'
import router from './router'
import utils from "@/utils";
import parser from "@/parser";
import '@/assets/css/tailwind.css'

window.Vue = require('vue').default;
import vueHeadful from 'vue-headful';

Vue.component('vue-headful', vueHeadful);

const store = Vue.observable({ metadata: null, header: null, subCategories: [], style: null, blob: { active: false }, id: null, type: null, projectData: [], categories: null, projects: null, category: null, project: null, json: null, post: null, views: null, news: null, events: null, footer: false, config: { disable: [""] } });
Vue.prototype.$store = store;

import VueFormulate from '@braid/vue-formulate'
Vue.use(VueFormulate)

Vue.config.productionTip = false

window.md = require("markdown-it")({
  html: true,
  xhtmlOut: true,
  linkify: true
});

import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

var markdownItAttrs = require("markdown-it-attrs");
var emoji = require("markdown-it-emoji");
import twemoji from "twemoji";

window.md.use(markdownItAttrs).use(emoji);

window.md.renderer.rules.emoji = function (token, idx) {
  return twemoji.parse(token[idx].content, { size: "svg", ext: ".svg" });
};

import VueTippy from "vue-tippy";
Vue.use(VueTippy, {
  appendTo: () => document.getElementById('project')
})

import VueMasonry from 'vue-masonry-css'
Vue.use(VueMasonry);

import { Swiper as SwiperClass, Pagination, Mousewheel, Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
SwiperClass.use([Pagination, Mousewheel, Autoplay])

import 'swiper/swiper-bundle.css'
Vue.use(getAwesomeSwiper(SwiperClass))

import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

Vue.use(VuePlyr, {
  plyr: {},
  appendTo: () => document.getElementById('content')
})

new Vue({
  router,
  mixins: [utils],
  render: h => h(App)
}).$mount('#app')

Vue.mixin(utils);
Vue.mixin(parser);