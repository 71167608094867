import moment from "moment";

export default {
  data: function () {
    return {
      json: null,
      metadata: null,
      post: null,
      event: null,
      category: null,
      type: null,
      project_data: ["hi"],
      airtable: null
    };
  },
  methods: {
    async fetchJSON(id) {
      await fetch("https://edgeryders.eu/t/" + id + ".json")
        .then((response) => response.json())
        .then((data) => {
          this.$store.json = data;
          this.$store.post = data.post_stream.posts[0].cooked;

          var meta = {
            id: data.id,
            title: data.title,
            date: data.created_at,
            tags: data.tags,
            author: data.details.created_by,
            event: data.event,
            word_count: data.word_count
          }
          this.$store.metadata = meta;
          this.fetchCategory('id', data.category_id);
        })
    },
    fetchCategories() {
      fetch("https://edgeryders.eu/site.json")
        .then((response) => response.json())
        .then((data) => {
          this.$store.categories = data.categories;
          var projects = data.categories.filter(x => x.uploaded_background && x.description);
          this.$store.projects = projects;
          var project_ids = projects.map(x => x.id);
          for (let i = 0; i < project_ids.length; i++) {
            var id = project_ids[i];
            fetch("https://edgeryders.eu/c/" + id + ".json")
              .then((response) => response.json())
              .then((data) => {
                var obj = {};
                obj["updated"] = data.topic_list.topics[0].created_at;
                obj["projectid"] = project_ids[i];
                this.$store.projectData.push(obj);
              });
          }
        })
        .catch(error => console.error(error));
    },
    getEvents(items) {
      return items.map(x => this.createEvent(x)).filter(x => !!x);
    },
    getSubCategories(id) {
      console.log(id);
      if (this.$store.type == 'project') {
        fetch("https://edgeryders.eu/categories.json")
          .then((response) => response.json())
          .then((data) => {
            var cat = data.category_list.categories.filter(x => x.slug.toLowerCase() == id);
            var subs = cat[0].subcategory_ids;
            console.log(subs);
            for (let i = 0; i < subs.length; i++) {
              var sub = this.$store.categories.filter(x => x.id == subs[i])[0]
              this.$store.subCategories.push(sub);
            }
          });
      }
    },
    getCategory(id) {
      return this.$store.categories.filter(x => x.id == id)[0]
    },
    createEvent(item) {
      console.log(item);
      var regex = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T(2[0-3]|[01][0-9]):[0-5][0-9]/g;
      var match = item.excerpt.match(regex);
      var url = "https://edgeryders.eu/t/" + item.id;
      var obj = {
        title: item.title,
        id: item.id,
        url: url,
        start: null,
        end: null,
        class: this.getCategory(item.category_id).slug
      }
      if (match && match[1]) {
        obj["start"] = match[0].replace("T", " ");
        obj["end"] = match[1].replace("T", " ");
        return obj;
      }
      else if (item.event && item.event.end) {
        obj["start"] = item.event.start.replace("T", " ");
        obj["end"] = item.event.end.replace("T", " ");
        return obj;
      } else {
        return null
      }
    },
    fetchCategory(type, value) {
      if (!this.$store.categories) {
        fetch("https://edgeryders.eu/site.json")
          .then((response) => response.json())
          .then((data) => {
            this.$store.categories = data.categories;
            this.setCategory(type, value);
            if (this.$store.type == 'project') { this.getSubCategories(value) }
          })
          .catch(error => console.error(error));
      } else {
        this.setCategory(type, value);
        if (this.$store.type == 'project') { this.getSubCategories(value) }
      }
    },
    setCategory(type, value) {
      if (type == 'id') {
        console.log('id');
        this.$store.category = this.$store.categories.filter(x => x.id == value)[0];
        console.log(this.$store.category);
      }
      if (type == 'slug') {
        this.$store.category = this.$store.categories.filter(x => x.slug == value)[0];
      }
      this.fetchProject()
    },
    loadPage(id) {
      var self = this;
      this.$store.id = id;
      fetch("https://edgeryders.eu/raw/" + id)
        .then(response => {
          response.text().then(function (text) {
            var clean_text = text.replace(/## Content(.*)-----/gs, '').replace(/---+/g, '--').replace(/#.* <h/g, "<h");
            self.convertJson(window.md.render(clean_text));
          });
        })
        .catch(error => console.error(error));
    },
    goTo(type, slug) {
      this.$store.type = null;
      this.$store.views = null;
      var path = slug || '';
      this.$router.push({ path: '../' + type + '/' + path });
      this.$store.type = type;
      window.scrollTo(0, 0)
    },
    goHome() {
      this.$store.type = 'home';
      this.$router.push({ path: '/', name: 'Home' });
    },
    urlTo(url) {
      window.location = url;
    },
    getMonth: function () {
      return moment(new Date()).format("MMMM YYYY");
    },
    getProfile(item, value) {
      if (value == "name") {
        return item.name || "@" + item.username;
      }
      if (value == "avatar") {
        return (
          "https://edgeryders.eu" + item.avatar_template.replace("{size}", 200)
        );
      }
      if (value == "url") {
        return "https://edgeryders.eu/u/" + item.username;
      }
    },
    getText(html) {
      var doc = document.createElement("div");
      doc.innerHTML = html;
      return doc.textContent
    },
    getAnchor(text) {
      return text.toLowerCase().replace(/ /g, '-')
    },
    getDescription(text) {
      if (text) {
        var element = document.createElement("div");
        element.innerHTML = text;
        return element.getElementsByTagName("p")[0].innerHTML;
      } else {
        return null;
      }
    },
    getLogo(url) {
      return "https://edgeryders.eu" + url
    },
    markdownRender(text) {
      return window.md.renderInline(text);
    },
    getAirtable() {
      let url = "https://api.airtable.com/v0/appmxTB6O0CZopGfT/Table%201";
      fetch(url, {
        method: 'Get',
        headers: {
          'Authorization': 'Bearer keyocggSHfh6E9gSg',
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((data) => { console.log(data); this.airtable = data; })
    },
    sendBaserow(data, type) {
      console.log(data);
      if (type == 'event') {
        var json = JSON.stringify({
          "Event": this.$store.metadata.title + ' - https://edgeryders.eu/t/' + this.$store.metadata.id,
          "Name": data.name,
          "Email": data.email,
          "Comment": data.comment || '',
          "Account": true,
          "Newsletter": false
        });
      }
      if (type == 'category') {
        var json = JSON.stringify({
          "Category": data.category,
          "Name": data.name,
          "Email": data.email,
          "Comment": data.comment || ''
        });

      }
      var table = "19461";

      console.log(table);
      console.log(json);
      var self = this;
      fetch("/.netlify/functions/baserow?table=" + table, {
        method: 'Post',
        body: json
      })
        .then(function (response) {
          // handle success
          self.success = true;
          window.console.log(response);
        })
        .catch(function (error) {
          // handle error
          window.console.log(error);
        })
    },
    sendAirtable(data) {
      let url = "https://api.airtable.com/v0/appmxTB6O0CZopGfT/Table%201";
      const body = {
        "records": [
          {
            "fields": {
              "Event": this.$store.metadata.title + ' - https://edgeryders.eu/t/' + this.$store.metadata.id,
              "Name": data.name,
              "Email": data.email,
              "Comment": data.comment || '',
              "Account": true,
              "Newsletter": false,
              "Time": Date.now()
            }
          }
        ]
      };

      fetch(url, {
        method: 'Post',
        body: JSON.stringify(body),
        headers: {
          'Authorization': 'Bearer keyocggSHfh6E9gSg',
          'Content-Type': 'application/json'
        }
      })
        .then(function (response) {
          // handle success
          window.console.log(response);
          this.success = true;
        })
        .catch(function (error) {
          // handle error
          window.console.log(error);
        })
        .finally(function () {
          // always executed
        });

    },
    async getEvent(id) {
      await this.fetchJSON(id);
      this.getEventMeta(this.$store.post);
      this.$store.type = 'event';
      this.loadPage(id);
    },
    async getNews(id) {
      await this.fetchJSON(id);
      this.getEventMeta(this.$store.post);
      this.$store.type = 'news';
      this.loadPage(id);
    },
    getProject(id) {
      this.$store.type = '';

      this.$store.subCategories = [];
      this.fetchCategory('slug', id);
      this.$store.type = 'project';
    },
    getPage(id) {
      this.$store.type = 'page';
      this.goTo('page', id)
    },
    fetchProject() {
      fetch("https://edgeryders.eu/c/" + this.$store.category.id + '.json')
        .then((response) => response.json())
        .then((data) => {

          var pages = data.topic_list.topics.filter(x => x.tags.includes('page')).sort(function (a, b) {
            return b.created_at.localeCompare(a.created_at);
          });
          var news = data.topic_list.topics.filter(x => x.tags.includes('news'));
          var events = data.topic_list.topics.filter(x => x.tags.includes('event'));
          var updates = events.concat(news);
          var users = data.users;
          var obj = {
            pages: pages,
            users: users,
            news: news,
            events: events,
            updates: updates
          }
          this.$store.project = obj;

          this.$store.style = {
            "--color": "#" + this.$store.category.color,
            "--plyr-color-main": "#" + this.$store.category.color
          };

        })
    },
    getEventMeta(post) {
      if (!this.$store.metadata.event) {
        var text = this.getText(post);
        console.log(text);
        var regex = /[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])T(2[0-3]|[01][0-9]):[0-5][0-9]/g;
        var match = text.match(regex);
        console.log(match);

      }
    }
  },
  filters: {
    dateShort: function (value) {
      return moment(String(value)).format("DD/MM/YY");
    },
    dateLong: function (value) {
      return moment(String(value)).format("dddd MMMM Do, YYYY");
    },
    timeShort: function (value) {
      return moment(String(value)).format("HH:mm");
    }
  }
};
