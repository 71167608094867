import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
var YAML = require("yamljs");

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

function lazyLoadPage(view){
  return() => import(`@/pages/${view}.vue`)
}

function getYaml(value) {
      const doc = new DOMParser().parseFromString(value, "text/html");
      var yaml = [...doc.querySelectorAll("code")].map(
        (code) => code.textContent
      );
      return YAML.parse(yaml[0]);
    }

function loadFromDirectory(address){
      fetch("https://edgeryders.eu/t/13671.json")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        var directory = getYaml(data.post_stream.posts[0].cooked);
        console.log(directory);
        var result = directory.filter((x) => x.domain == address)[0];
        if (result && result.id) {
          window.location = 'https://start.edgeryders.eu/page/' + result.id
        } else {
          return false
        }
      });
    }

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: (to, from, next) => {
      var address = window.location.hostname;
      switch(address) {
        case "start.edgeryders.eu": case "now.edgeryders.eu": case "localhost": case "live.edgeryders.eu":
          next()
          break;
        default:
          loadFromDirectory(address);
      }
    },
    component: lazyLoad('Home')
  },
  {
    path: '/projects',
    name: 'Projects',
    component: lazyLoadPage('Projects')
  },
  {
    path: '/page/:id',
    name: 'Page',
    component: lazyLoad('Page')
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: lazyLoad('Project')
  },
    {
    path: '/news/:id',
    name: 'News',
    component: lazyLoad('News')
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: lazyLoad('Event')
  },
  {
    path: '/*/:id',
    name: 'Page',
    component: lazyLoad('Page')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
