<template>
  <div class="footer" id="footer" :class="$store.type">
    <div class="wrapper">
      <div class="terms">
        <h3>Terms of Participation &amp; Privacy Policy</h3>
        <p>
          The Edgeryders online platform and activities are intended for people
          to cooperate within and across projects trying to build a better
          world. The word "better" has here a fairly broad range of meaning.
          These
          <a href="https://edgeryders.eu/t/netiquette/45" target="_blank"
            >Community Guidelines</a
          >
          are here to help you understand what it means to be a member of
          Edgeryders. Don’t forget that your use of Edgeryders is subject to
          these Community Guidelines and our
          <a href="https://edgeryders.eu/t/id/44" target="_blank"
            >Terms of Service.</a
          >
        </p>
      </div>
      <div>
        <ul>
          <li><a href="mailto:nadia@edgeryders.eu">Email</a></li>
          <li><a href="https://twitter.com/edgeryders">Twitter</a></li>
          <li><a href="https://t.me/edgeryders">Telegram</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>
<style lang="scss" scoped>
.footer {
  background: #000;
  padding: 4rem 0 3rem;
  @apply mt-8;
  &.home {
    .wrapper {
      width: 85%;
      max-width: 100%;
    }
  }
}

.wrapper {
  width: 80%;
  display: flex;
  font-family: "Helvetica";
  color: white;
  max-width: 1080px;
  @apply mx-auto;
  position: relative;
  .terms {
    padding: 0 5rem 0 0;
    a {
      @apply underline;
    }
    h3 {
      margin-bottom: 1rem;
      font-weight: bold;
      color: white;
    }
  }
  p {
    line-height: 1.5rem;
  }
  ul {
    list-style: none;
    margin: -1rem 0 0 0;
    display: inline-block;
    width: auto;
    padding: 0;
    min-width: 300px;
    li {
      padding: 1rem 0.2rem;
      font-weight: bold;
      border-bottom: 1px solid #fff;
      color: white;
      &:last-child {
        border: none;
      }
      a {
        color: white;
        text-decoration: none;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    @apply flex-col;
    width: 100%;
    margin: 0rem auto 3rem;
    ul {
      @apply mt-3;
      width: 100%;
      padding: 0 7.5%;
    }
    .terms {
      padding: 0 5%;
    }
  }

  .footer.home {
    .wrapper {
      width: 85%;
      margin: 0 auto;
      ul {
        width: 100%;
        padding: 0;
      }
      .terms {
        padding: 0;
      }
    }
  }
}
</style>
