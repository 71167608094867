YAML = require('yamljs');
export default {
  data: function () {
    return {
      siteviews: null,
      header: null,
      menu: null,
      logos: null
    };
  },
  methods: {
    convertJson(text) {
      var regex = /&(?!#?[a-z0-9]+;)/gm;
      var html = text.replace('<br>', '<br />').replace(/\n/g, '').replace(regex, "&amp;").replace('upload://', '');

      const doc = new DOMParser().parseFromString("<body>" + html + "</body>", "text/xml");

      if (doc.querySelector('pre')) {
        var code = doc.querySelector('pre').textContent;
        doc.querySelector('pre').remove();
        this.$store.config = YAML.parse(code);
        console.log(this.$store.config)
      }
      console.log(code);
      var main = doc.querySelector('body').childNodes;
      var array = Array.prototype.slice.call(main);
      this.json = array.map((x) => x.outerHTML);
      this.createViews(this.json);
    },
    createViews(array) {

      var views = [];


      for (var x = 0; x < array.length; x++) {

        var element = document.createElement("div");
        element.innerHTML = array[x];
        element = element.firstElementChild;
        let name = element.nodeName;
        var view;
        var header = null;


        if (element.nodeName == "MENU") {
          view = this.createMenu(element);
          this.menu = view;
        }
        if (element.nodeName == "HEADER") {
          view = this.parseHeader(element);
          header = view;
        }
        if (element.nodeName == "LOGOS") {
          console.log('logos');
          console.log(element);
          view = this.parseLogos(element);
          this.$store.logos = view;
        }
        if (element.nodeName == "H1" || element.nodeName == "H2" || element.nodeName == "H3") {
          view = this.createTitle(element);
          views.push(view)
        }
        if (element.nodeName == "ROW") {
          view = this.createRow(element);
          views.push(view)
        }

        if (element.nodeName == "GRID") {
          view = this.createGrid(element);
          views.push(view)
        }
        if (element.nodeName == "LIST") {
          view = this.createList(element);
          views.push(view)
        }
        if (element.nodeName == "CARDS") {
          view = this.createCards(element);
          views.push(view)
        }
        if (element.nodeName == "GALLERY") {
          view = this.createGallery(element);
          views.push(view)
        }
        if (element.nodeName == "CONTACT") {
          view = this.createContact(element);
          views.push(view)
        }
        if (element.nodeName == "PRE") {
          view = this.createCode(element);
          views.push(view);
        }
        if (element.nodeName == "PREVIEW") {
          view = this.createPreview(element);
          views.push(view);
        }
        if (element.nodeName == "TABLE") {
          view = this.createText(element);
          views.push(view);
        }
        if (name == "P" || element.nodeName == "UL" || element.nodeName == "OL" || element.nodeName == "STRONG" || element.nodeName == "LIST") {
          view = this.createText(element);
          var regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/g;
          var timezone = /(Europe)(.*?)(?="])/g;
          if (element.innerText.match(timezone)) {
            var date = element.innerText.match(regex);
            var regexTime = /(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]/g;
            var time = element.innerText.match(regexTime);
            var zone = element.innerText.match(timezone);
            var obj = {
              start: date[0] + 'T' + time[0] + ":00",
              end: date[1] + 'T' + time[1] + ":00",
              timezone: zone[0]
            }
            this.$store.metadata.event = obj;
            views.push(view)
          }
          else if (element.firstElementChild) {
            var childName = element.firstElementChild.nodeName;
            var childContent = element.firstElementChild;
            if (childName == "IMG" && childContent.className !== 'emoji') {
              console.log(childContent.className);
              view = this.createImage(childContent)
            }
            if (this.parseVideo(childContent.innerHTML)) {
              view = this.parseVideo(childContent.innerHTML);
            }
            else if (childName == "A") {
              view = this.createText(element);
            }
            views.push(view)
          } else {
            views.push(view)
          }

        }


      }

      if (!header && this.$store.type == 'news') {
        if (views[0].type == 'image') {
          if (views[0].image) {
            image = views[0].image
          }
          if (views[0].video) {
            video = views[0].video
          }
          views.splice(0, 1);
        }
      }

      if (!header && this.$store.type == 'page') {
        var image; var video; var title; var text; var links;


        if (views[0].type == 'image') {
          if (views[0].image) {
            image = views[0].image
          }
          if (views[0].video) {
            video = views[0].video
          }
          views.splice(0, 1);
        }
        if (views[0].level == 'H1') {
          title = views[0].content;
          views.splice(0, 1);
        }
        if (views[0].type == 'text' && String(views[0].content).length < 430) {
          text = views[0].content;
          views.splice(0, 1);
        }
        if (text && views[0].type == 'text') {
          links = this.createLinks(views[0].content);
          views.splice(0, 1);
        }

        header = {
          type: "header",
          title: title,
          text: text,
          image: image,
          video: video,
          links: links
        }

        this.$store.header = header;

      }

      this.$store.views = views;

    },
    parseVideo(text) {
      var hasVideo = text.match(
        /(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\S+)?/
      );

      var obj = {
        "type": "video",
        "content": null
      }

      if (hasVideo && RegExp.$3.indexOf("youtu") > -1) {
        obj["youtube"] = RegExp.$6;
        return obj
      } else if (hasVideo && RegExp.$3.indexOf("vimeo") > -1) {
        obj["vimeo"] = RegExp.$6;
        return obj
      } else {
        return false;
      }
    },
    createMenu(view) {
      var obj = {
        type: "menu",
        content: view.textContent
      }
      return obj;
    },
    createList(view) {
      var obj = {
        type: "list",
        config: this.getAttributes(view)
      }
      return obj;
    },
    createCard(element) {
      var doc = document.createElement("div");
      doc.innerHTML = element;
    console.log(doc);
      var obj = {
        title: null,
        image: null,
        icon: null,
        text: null,
        url: null
      }
      if (doc.querySelector("a")) {
        obj['title'] = doc.querySelector("a").textContent;
      }
  
      if (doc.querySelector("img.emoji")) {
        obj['icon'] = doc.querySelector("img.emoji").getAttribute("src");
        doc.querySelector("img.emoji").remove();
      }
      if (doc.querySelector("img")) {
        obj['image'] = doc.querySelector("img").getAttribute("src");
      }
      if (doc.querySelectorAll("p")[1]) {
        obj['text'] = doc.querySelectorAll("p")[1].textContent;
      }
      if (doc.querySelectorAll("p")[2]) {
        obj['text'] = doc.querySelectorAll("p")[2].textContent;
      }
      if (doc.querySelector("a")) {
        obj['url'] = doc.querySelector("a").getAttribute("href");
      }
      return obj
    },
    createCards(view) {
      var cards = view.innerHTML.split('<p>--</p>');
      var array = cards.map((x) => this.createCard(x));
      var obj = {
        type: "cards",
        content: array,
        config: this.getAttributes(view)
      }
      return obj;
    },
    createGrid(view) {
      var items = view.innerHTML.split('<p>--</p>');
      var array = items.map((x) => this.createCard(x));
      var obj = {
        type: "grid",
        content: array,
        config: this.getAttributes(view)
      }
      return obj;
    },
    createTitle(view) {
      var obj = {
        type: "title",
        content: view.textContent,
        level: view.nodeName,
        config: this.getAttributes(view)
      }
      return obj;
    },
    createText(view) {
      var obj = {
        type: "text",
        content: view.outerHTML
      }
      return obj;
    },
    createRow(view) {
      var columns = view.innerHTML.split('<p>--</p>');
      var config = this.getAttributes(view);
      var widths = config['split'].split('/');
      var obj = {
        type: "row",
        content: columns,
        config: config,
        width: widths
      }
      return obj;
    },
    createHeader(view) {
      var obj = {
        type: "header",
      }
      var elements = this.parseHeader(view.innerHTML);
      obj["title"] = elements.title;
      obj["subtitle"] = elements.subtitle;
      obj["text"] = elements.text;

      if (view.hasAttributes()) {
        var config = this.getAttributes(view);
        obj["config"] = config;
      }

      return obj;
    },
    openLink(url) {
      window.open(url);
    },
    createGallery(view) {
      var images = view.querySelectorAll('img');
      var array = Array.prototype.slice.call(images).map((x) => this.createImage(x));
      var obj = {
        type: "gallery",
        content: array,
        config: this.getAttributes(view)
      }
      return obj;
    },
    createImage(element) {
      var src = element.getAttribute("src");
      var file = src.split('.').pop();
      var obj = {};
      if (file == 'mp4') {
        obj["video"] = src
      } else {
        obj["image"] = src;
      }
      obj["type"] = 'image';
      obj["text"] = element.getAttribute("alt");
      return obj
    },
    markdownRender(text) {
      return window.md.renderInline(text);
    },
    mdRender(text) {
      return window.md.render(text);
    },
    parseJson(value) {
      var code = value.match(/```([^`]*)```/);
      if (code) {
        return JSON.parse('{' + code[1] + '}').data;
      } else {
        return false;
      }
    },
    parseLogos(view) {
      console.log(view);

      var images = view.querySelectorAll('img');
      console.log(images);
      var array = Array.prototype.slice.call(images).map((x) => this.createImage(x));

      var obj = {
        type: "logos",
        content: array,
        config: this.getAttributes(view)
      }
      console.log(obj);
      return obj;
    },
    parseTable(text) {
      var html = window.md.render(text);
      const doc = new DOMParser().parseFromString(html, "text/xml");
      var keys = Array.prototype.map.call(doc.querySelectorAll('th'), function (th) {
        return th.innerHTML;
      });
      var data = Array.prototype.map.call(doc.querySelector('tbody').querySelectorAll('tr'), function (tr) {
        var obj = new Object();
        Array.prototype.map.call(tr.querySelectorAll('td'), function (td, index) {
          var key = keys[index];
          var div = document.createElement("div");
          div.innerHTML = td.textContent;
          if (key == 'title' || key == 'text') {
            obj[key] = td.innerHTML;
          } else {
            obj[key] = div.textContent;
          }

        });
        return obj;
      });

      return data;
    },
    parseHeader(element) {
      var title = element.querySelector("h1").outerHTML;
      var text = element.querySelector("p").outerHTML;
      var image = element.getAttribute("image");
      var background = element.getAttribute("background");
      var sub = element.querySelectorAll("p");
      var links;
      if (sub[1]) {
        links = this.createLinks(sub[1].innerHTML)
      }
      var obj = {
        type: "header",
        title: title,
        text: text,
        color: background,
        image: image,
        links: links,
        config: this.getAttributes(element)
      }
      var img = element.querySelector('img');
      if (img) {
        var imgObj = {
          src: img.getAttribute("src"),
          url: img.getAttribute("url"),
          caption: img.getAttribute("caption")
        }
        obj["subImg"] = imgObj;
      }
      if (element.querySelector("h2")) {
        obj["subtitle"] = element.querySelector("h2").outerHTML;
      }
      return obj;
    },
    createGrid2(view) {
      var text = view.innerHTML.split('<p>--</p>');
      var obj = {
        type: "grid",
        content: text,
        config: this.getAttributes(view)
      }
      return obj;
    },
    getAttributes(element) {
      var attrs = element.attributes;
      var obj = {};
      for (var i = attrs.length - 1; i >= 0; i--) {
        var attrname = attrs[i].name;
        var attrvalue = attrs[i].value;
        if (attrvalue == "true") {
          obj[attrname] = true;
        } else if (attrvalue == "false") {
          obj[attrname] = false;
        } else {
          obj[attrname] = attrvalue;
        }
      }
      return obj;
    },
    createLinks(element) {
      var doc = document.createElement("div");
      doc.innerHTML = element;
      var links = doc.querySelectorAll('a');
      var array = Array.prototype.slice.call(links).map((x) => this.createLink(x));
      return array;
    },
    createLink(element) {
      var obj = {
        url: element.getAttribute("href"),
        text: element.textContent
      }
      return obj
    }

  },
  filters: {

  }
};
