<template>
  <div id="app">
    <Menu />
    <div class="content">
      <transition :name="transitionName" mode="out-in">
        <router-view />
      </transition>
    </div>
    <Footer v-if="$store.footer && !$store.config.disable.includes('footer')" />
  </div>
</template>
<script>
import Menu from "@/components/Menu.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  data: function() {
    return {
      style: null,
      transitionName: null,
      transitionMode: null,
      metaInfo: {
        title: "Edgeryders | Start",
        titleTemplate: "%s | Edgeryders",
      },
    };
  },
  components: {
    Menu,
    Footer,
  },
  methods: {
    handleScroll() {
      let menu = document.querySelector(".menu");
      var height = 200;
      if (window.scrollY > height) {
        menu.classList.add("active");
      } else {
        menu.classList.remove("active");
      }
    },
  },
  mounted() {
    if (process.browser) {
      window.addEventListener("scroll", this.handleScroll);
    }

    setTimeout(() => {
      this.$store.footer = true;
    }, 2000);
  },
  created() {},
};
</script>

<style lang="scss">
.overflow-hidden {
  overflow: hidden;
}
.scale-slide-enter-active,
.scale-slide-leave-active {
  transition: transform 0.85s ease, opacity 1s ease;

  header {
    transition: transform 0.85s ease, opacity 1s ease;
  }
}

.scale-slide-leave-from {
  header {
    transform: scale(1);
  }
}

.scale-slide-leave-to {
  header {
    transform: scale(0.8);
    opacity: 0;
  }
}

/*flip transition*/
.flip-enter-active,
.flip-leave-active {
  transition: all 0.33s ease-out;
}
.flip-enter {
  transform: scale(1);
}
.flip-leave-to {
  transform: scale(0.98);
  opacity: 0;
}

.scale-slide-leave-active {
  position: absolute;
  padding: 0 4.2%;
}

.scale-slide-leave-from {
  perspective: 100px;
}

.scale-leave-active {
  transition: all 1s ease;
  header {
    transition: all 1s ease;
  }
  main {
    transition: all 1s ease;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 2.75s ease-out;
}

.slide-enter-to {
  position: absolute;
  right: 0;
}

.slide-enter-from {
  position: absolute;
  right: -100%;
}

.slide-leave-to {
  position: absolute;
  left: -100%;
}

.slide-leave-from {
  position: absolute;
  left: 0;
}

.scale-leave-to {
  header {
    opacity: 0;
    transform: scale(0.9);
    transition-delay: 0s;
    transition: all 1s ease;
  }
  main {
    opacity: 0;
    transform: scale(0.9);
    transition-delay: 0.3s;
    transition: all 1s ease;
  }
}

.zoom-enter-active,
.zoom-leave-active {
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-name: zoom;
}

.zoom-leave-active {
  animation-direction: reverse;
}

@keyframes zoom {
  from {
    opacity: 1;
    transform: scale3d(1, 1, 1);
    transform: rotateX(0deg);
  }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
    transform: rotateX(20deg);
  }
}

.slice {
  height: 480px;
  width: 53.3%;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(0% 0%, 100% 0%, 84.5% 100%, 0% 100%);
  z-index: 9999;
  transition: width 0.5s ease, height 0.2s ease, clip-path 0.3s ease;

  &.active {
    width: 100%;
    height: 500px;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
#app {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}

html,
body {
  height: 100%;
  margin: 0;
}

.headbg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 400px;
  background: blue;
}

.content {
  position: relative;
  width: 100%;
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}

.morph {
  position: fixed;
  opacity: 0;
  @apply rounded-full;
  box-shadow: none;
  background: rgba(255, 255, 255, 0.1);
  &.active {
    z-index: 99999999;
    background: rgba(255, 255, 255, 0);
    width: 40.6% !important;
    height: 400px !important;
    border-radius: 0;
    opacity: 1;
    left: 0 !important;
    top: 0 !important;
    transition: width 0.3s, background 0.8s, height 0.3s, top 0.3s, left 0.3s,
      border-radius 0.2s;

    clip-path: polygon(0% 0%, 100% 0%, 80.4% 100%, 0% 100%);
  }
}

@media only screen and (max-width: 600px) {
  .morph {
    display: none;
  }
  .slice {
    display: none;
  }
}
</style>
