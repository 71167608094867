<template>
  <nav
    class="menu"
    :class="[$store.type, { image: isImage() }]"
    :style="$store.style"
  >
    <div class="inline-flex items-center h-full">
      <span
        class="title pr-0 mr-0"
        v-if="!$store.config.disable.includes('logo')"
      >
        <a href="https://edgeryders.org" class="logo"></a>
        <span class="home" @click="goHome()">Start</span>
      </span>
      <span v-else class="title">
        <a
          :href="'https://start.edgeryders.eu/page/' + $store.metadata.id"
          class="font-bold pl-4 md:pl-6"
        >
          <span class="home">{{ $store.metadata.title }}</span>
        </a>
      </span>

      <!-- <div ref="tag" class="tag category" :style="'color: #' + $store.category.color" >
      <img v-if="$store.category.uploaded_background" :src="getLogo($store.category.uploaded_logo.url)" />
      <span>{{$store.category.name}}</span>
    </div> -->
    </div>
    <scrollactive v-if="$store.type == 'page'" :offset="80" class="links">
      <a
        v-for="(item, index) in getItems()"
        class="scrollactive-item"
        :key="index"
        :href="item.url"
        >{{ item.text }}</a
      >
      <a
        class="scrollactive-item"
        v-if="!$store.config.disable.includes('footer')"
        href="#footer"
        >Contact Us</a
      >

      <div
        class="share pl-4"
        v-if="
          getItems().length < 6 && !$store.config.disable.includes('social')
        "
      >
        <a class="twitter" :href="socialLink('twitter')"></a>
        <a class="facebook" :href="socialLink('facebook')"></a>
        <a class="email" :href="socialLink('email')"></a>
      </div>
    </scrollactive>
    <scrollactive v-if="$store.type == 'project'" :offset="80" class="links">
      <a class="scrollactive-item" href="#news">News</a>
      <a class="scrollactive-item" href="#pages">Pages</a>
      <a class="scrollactive-item" href="#events">Events</a>
      <a class="scrollactive-item" href="#contributors">Contributors</a>
      <a class="scrollactive-item" href="#footer">Contact Us</a>
    </scrollactive>
    <scrollactive v-if="$store.type == 'home'" :offset="80" class="links">
      <a class="scrollactive-item" href="#news">News</a>
      <a class="scrollactive-item" href="#events">Events</a>
      <a class="scrollactive-item" href="#projects">Projects</a>
      <a class="scrollactive-item" href="#footer">Contact Us</a>
    </scrollactive>

    <div class="share pl-4" v-if="$store.type == 'news'">
      <a class="twitter" :href="socialLink('twitter')"></a>
      <a class="facebook" :href="socialLink('facebook')"></a>
      <a class="email" :href="socialLink('email')"></a>
    </div>

    <div
      class="mobile_menu_icon"
      @click="toggleMenu"
      :class="{ active: mobileMenu }"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <div class="mobile_menu" :class="{ active: mobileMenu }">
      <div class="mobile_menu_container">
        <div class="page_menu" v-if="$store.type == 'page'">
          <h2 class="title">{{ $store.header.title }}</h2>
          <div class="buttons" v-if="$store.header.links">
            <a
              v-for="(item, index) in $store.header.links"
              class="mobile-nav-item"
              :key="index"
              :href="item.url"
              >{{ item.text }}</a
            >
          </div>
        </div>

        <h2 class="title" v-if="$store.type == 'project'">
          {{ $store.category.name }}
        </h2>

        <div v-if="$store.type == 'home'">
          <h2 class="font-bold mb-2 border-b pb-3">Welcome to Edgeryders</h2>
        </div>

        <scrollactive
          :offset="80"
          class="mobile_links"
          v-if="$store.type == 'home'"
        >
          <a class="scrollactive-item" @click="toggleMenu()" href="#news"
            >News</a
          >
          <a class="scrollactive-item" @click="toggleMenu()" href="#events"
            >Events</a
          >
          <a class="scrollactive-item" @click="toggleMenu()" href="#projects"
            >Projects</a
          >
          <a class="scrollactive-item" @click="toggleMenu()" href="#footer"
            >Contact Us</a
          >
        </scrollactive>

        <scrollactive
          :offset="80"
          class="mobile_links"
          v-if="$store.type == 'project'"
        >
          <a class="scrollactive-item" @click="toggleMenu()" href="#news"
            >News</a
          >
          <a class="scrollactive-item" @click="toggleMenu()" href="#pages"
            >Pages</a
          >
          <a class="scrollactive-item" @click="toggleMenu()" href="#events"
            >Events</a
          >
          <a
            class="scrollactive-item"
            @click="toggleMenu()"
            href="#contributors"
            >Contributors</a
          >
          <a class="scrollactive-item" @click="toggleMenu()" href="#footer"
            >Contact Us</a
          >
        </scrollactive>

        <scrollactive
          v-if="$store.type == 'page'"
          :offset="80"
          class="mobile_links"
        >
          <a
            v-for="(item, index) in getItems()"
            @click="toggleMenu()"
            class="scrollactive-item"
            :key="index"
            :href="item.url"
            >{{ item.text }}</a
          >
          <a class="scrollactive-item" @click="toggleMenu()" href="#footer"
            >Contact Us</a
          >
        </scrollactive>

        <div class="mobile_links social">
          <h3>Share this</h3>
          <a
            :href="socialLink('twitter')"
            @click="toggleMenu()"
            class="mobile-nav-item twitter"
            target="_blank"
            >Twitter</a
          >
          <a
            :href="socialLink('facebook')"
            @click="toggleMenu()"
            class="mobile-nav-item facebook"
            target="_blank"
            >Facebook</a
          >
          <a
            :href="socialLink('linkedin')"
            @click="toggleMenu()"
            class="mobile-nav-item linkedin"
            target="_blank"
            >LinkedIn</a
          >
          <a
            :href="socialLink('email')"
            @click="toggleMenu()"
            class="mobile-nav-item email"
            target="_blank"
            >Email</a
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Menu",
  props: {},
  data() {
    return {
      mobileMenu: false,
    };
  },
  methods: {
    isImage() {
      if (this.$store.type == "page" && this.$store.json.image_url) {
        return true;
      } else {
        return false;
      }
    },
    toggleMenu() {
      this.mobileMenu = !this.mobileMenu;
    },
    socialLink(network) {
      var title = "";
      var link = location.href;
      if (network == "twitter") {
        return "https://twitter.com/intent/tweet?text=" + title + " - " + link;
      }
      if (network == "facebook") {
        return "https://www.facebook.com/sharer/sharer.php?u=" + link;
      }
      if (network == "linkedin") {
        return "https://www.linkedin.com/sharing/share-offsite/?url=" + link;
      }
      if (network == "email") {
        return "mailto:?subject=" + title + "&body=" + " " + link;
      }
    },
    getItems() {
      return this.$store.views
        .filter((x) => x.level == "H2")
        .map((x) => this.createLink(x));
    },
    createLink(item) {
      var obj = {
        text: item.content,
        url: this.getAnchor(item.content),
      };
      return obj;
    },
    getAnchor(text) {
      return "#" + text.toLowerCase().replace(/ /g, "-");
    },
    goHome() {
      this.$store.type = "home";
      this.$router.push({ path: "/", name: "Home" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.title {
  span.home {
    @apply text-lg;
  }
}

.menu.event {
  a.logo {
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 250a250 250 0 11500 0 250 250 0 01-500 0zm97-103v106h42v-16h-26v-31h19v-16h-19v-27h26v-16zm80 91c6 0 8-4 8-11v-52c0-8-4-12-13-12h-4v75zm-25-91h23c19 0 27 10 27 29v48c0 20-8 29-26 29h-24zm63 25c0-17 9-27 25-27s24 9 24 27v10h-16v-11c0-8-3-11-8-11-6 0-9 3-9 11v59c0 7 3 11 9 11 5 0 8-4 8-11v-17h-10v-13h26v53h-8l-3-7c-4 7-9 10-16 10-14 0-22-11-22-30zm64-25h43v16h-26v27h18v16h-18v31h26v16h-43zM112 381H95V275h24c18 0 26 8 26 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm0-60v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm53-46l10 42h1l10-42h17l-19 62v44h-16v-42l-20-64zm69 90c6 0 9-4 9-11v-51c0-9-4-13-13-13h-4v75zm-24-90h23c18 0 27 9 27 29v47c0 20-8 30-26 30h-24zm136 46v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm-72-46h43v15h-26v28h19v15h-19v32h26v16h-43zm72 106h-16V275h24c17 0 25 8 25 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm57-30c1 11 4 16 11 16 6 0 10-4 10-10 0-9-6-14-15-23-12-12-20-21-20-35 0-15 11-26 25-26s24 10 25 26l-16 2c-1-9-3-13-9-13-5 0-9 4-9 10 0 9 7 16 17 25 11 11 18 19 18 34s-11 26-26 26-26-11-27-29zm-65-172v-42h-42a42 42 0 1142 42z' fill='white' fill-rule='evenodd'/%3E%3C/svg%3E")
      no-repeat 0 center;
  }
  .title {
    span.home {
      color: white;
    }
  }
}
.menu {
  @apply fixed flex justify-between text-white items-center w-full;
  z-index: 9999999;
  top: 0px;
  height: 60px;
  border-bottom: 1px solid transparent;
  background: rgba(0, 0, 0, 0.02);
  transition: all 0.4s ease;
  .title {
    @apply flex items-center h-full;
    span.home {
      @apply font-bold;
      &:hover {
        cursor: pointer;
        @apply underline;
      }
    }
  }
  &.image {
    background: rgba(0, 0, 0, 0.04) !important;
    border: none !important;
    color: white !important;
    .title {
      span.home {
        color: white;
      }
    }
    .links {
      a {
        color: white !important;
        border-color: rgba(255, 255, 255, 0.2) !important;
      }
    }
    a.logo {
      background: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 250a250 250 0 11500 0 250 250 0 01-500 0zm97-103v106h42v-16h-26v-31h19v-16h-19v-27h26v-16zm80 91c6 0 8-4 8-11v-52c0-8-4-12-13-12h-4v75zm-25-91h23c19 0 27 10 27 29v48c0 20-8 29-26 29h-24zm63 25c0-17 9-27 25-27s24 9 24 27v10h-16v-11c0-8-3-11-8-11-6 0-9 3-9 11v59c0 7 3 11 9 11 5 0 8-4 8-11v-17h-10v-13h26v53h-8l-3-7c-4 7-9 10-16 10-14 0-22-11-22-30zm64-25h43v16h-26v27h18v16h-18v31h26v16h-43zM112 381H95V275h24c18 0 26 8 26 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm0-60v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm53-46l10 42h1l10-42h17l-19 62v44h-16v-42l-20-64zm69 90c6 0 9-4 9-11v-51c0-9-4-13-13-13h-4v75zm-24-90h23c18 0 27 9 27 29v47c0 20-8 30-26 30h-24zm136 46v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm-72-46h43v15h-26v28h19v15h-19v32h26v16h-43zm72 106h-16V275h24c17 0 25 8 25 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm57-30c1 11 4 16 11 16 6 0 10-4 10-10 0-9-6-14-15-23-12-12-20-21-20-35 0-15 11-26 25-26s24 10 25 26l-16 2c-1-9-3-13-9-13-5 0-9 4-9 10 0 9 7 16 17 25 11 11 18 19 18 34s-11 26-26 26-26-11-27-29zm-65-172v-42h-42a42 42 0 1142 42z' fill='white' fill-rule='evenodd'/%3E%3C/svg%3E")
        no-repeat 0 center;
    }
    &.active {
      background: white !important;
      border-bottom: 1px solid #efefef !important;
      a.logo {
        background: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 250a250 250 0 11500 0 250 250 0 01-500 0zm97-103v106h42v-16h-26v-31h19v-16h-19v-27h26v-16zm80 91c6 0 8-4 8-11v-52c0-8-4-12-13-12h-4v75zm-25-91h23c19 0 27 10 27 29v48c0 20-8 29-26 29h-24zm63 25c0-17 9-27 25-27s24 9 24 27v10h-16v-11c0-8-3-11-8-11-6 0-9 3-9 11v59c0 7 3 11 9 11 5 0 8-4 8-11v-17h-10v-13h26v53h-8l-3-7c-4 7-9 10-16 10-14 0-22-11-22-30zm64-25h43v16h-26v27h18v16h-18v31h26v16h-43zM112 381H95V275h24c18 0 26 8 26 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm0-60v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm53-46l10 42h1l10-42h17l-19 62v44h-16v-42l-20-64zm69 90c6 0 9-4 9-11v-51c0-9-4-13-13-13h-4v75zm-24-90h23c18 0 27 9 27 29v47c0 20-8 30-26 30h-24zm136 46v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm-72-46h43v15h-26v28h19v15h-19v32h26v16h-43zm72 106h-16V275h24c17 0 25 8 25 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm57-30c1 11 4 16 11 16 6 0 10-4 10-10 0-9-6-14-15-23-12-12-20-21-20-35 0-15 11-26 25-26s24 10 25 26l-16 2c-1-9-3-13-9-13-5 0-9 4-9 10 0 9 7 16 17 25 11 11 18 19 18 34s-11 26-26 26-26-11-27-29zm-65-172v-42h-42a42 42 0 1142 42z' fill='orangered' fill-rule='evenodd'/%3E%3C/svg%3E")
          no-repeat 0 center;
      }
      .links {
        a {
          color: black !important;
          border-color: rgba(0, 0, 0, 0.05) !important;
        }
      }
    }
  }
  .links {
    @apply flex text-white;
    height: 50%;
    transition: height 0.2s ease;
    a {
      @apply font-bold flex border-l items-center px-5;
      border-color: rgba(255, 255, 255, 0.1);
      &:first-child {
        border: none;
      }
    }
  }
  a.logo {
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 250a250 250 0 11500 0 250 250 0 01-500 0zm97-103v106h42v-16h-26v-31h19v-16h-19v-27h26v-16zm80 91c6 0 8-4 8-11v-52c0-8-4-12-13-12h-4v75zm-25-91h23c19 0 27 10 27 29v48c0 20-8 29-26 29h-24zm63 25c0-17 9-27 25-27s24 9 24 27v10h-16v-11c0-8-3-11-8-11-6 0-9 3-9 11v59c0 7 3 11 9 11 5 0 8-4 8-11v-17h-10v-13h26v53h-8l-3-7c-4 7-9 10-16 10-14 0-22-11-22-30zm64-25h43v16h-26v27h18v16h-18v31h26v16h-43zM112 381H95V275h24c18 0 26 8 26 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm0-60v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm53-46l10 42h1l10-42h17l-19 62v44h-16v-42l-20-64zm69 90c6 0 9-4 9-11v-51c0-9-4-13-13-13h-4v75zm-24-90h23c18 0 27 9 27 29v47c0 20-8 30-26 30h-24zm136 46v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm-72-46h43v15h-26v28h19v15h-19v32h26v16h-43zm72 106h-16V275h24c17 0 25 8 25 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm57-30c1 11 4 16 11 16 6 0 10-4 10-10 0-9-6-14-15-23-12-12-20-21-20-35 0-15 11-26 25-26s24 10 25 26l-16 2c-1-9-3-13-9-13-5 0-9 4-9 10 0 9 7 16 17 25 11 11 18 19 18 34s-11 26-26 26-26-11-27-29zm-65-172v-42h-42a42 42 0 1142 42z' fill='orangered' fill-rule='evenodd'/%3E%3C/svg%3E")
      no-repeat 0 center;
    background-size: 35px !important;
    padding-left: 35px !important;
    height: 100%;
    @apply mx-4 mr-3 font-bold flex items-center text-lg;
  }
  &.home {
    a.logo {
      background: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 250a250 250 0 11500 0 250 250 0 01-500 0zm97-103v106h42v-16h-26v-31h19v-16h-19v-27h26v-16zm80 91c6 0 8-4 8-11v-52c0-8-4-12-13-12h-4v75zm-25-91h23c19 0 27 10 27 29v48c0 20-8 29-26 29h-24zm63 25c0-17 9-27 25-27s24 9 24 27v10h-16v-11c0-8-3-11-8-11-6 0-9 3-9 11v59c0 7 3 11 9 11 5 0 8-4 8-11v-17h-10v-13h26v53h-8l-3-7c-4 7-9 10-16 10-14 0-22-11-22-30zm64-25h43v16h-26v27h18v16h-18v31h26v16h-43zM112 381H95V275h24c18 0 26 8 26 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm0-60v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm53-46l10 42h1l10-42h17l-19 62v44h-16v-42l-20-64zm69 90c6 0 9-4 9-11v-51c0-9-4-13-13-13h-4v75zm-24-90h23c18 0 27 9 27 29v47c0 20-8 30-26 30h-24zm136 46v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm-72-46h43v15h-26v28h19v15h-19v32h26v16h-43zm72 106h-16V275h24c17 0 25 8 25 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm57-30c1 11 4 16 11 16 6 0 10-4 10-10 0-9-6-14-15-23-12-12-20-21-20-35 0-15 11-26 25-26s24 10 25 26l-16 2c-1-9-3-13-9-13-5 0-9 4-9 10 0 9 7 16 17 25 11 11 18 19 18 34s-11 26-26 26-26-11-27-29zm-65-172v-42h-42a42 42 0 1142 42z' fill='white' fill-rule='evenodd'/%3E%3C/svg%3E")
        no-repeat 0 center;
    }
  }
  &.project {
    a.logo {
      background: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 250a250 250 0 11500 0 250 250 0 01-500 0zm97-103v106h42v-16h-26v-31h19v-16h-19v-27h26v-16zm80 91c6 0 8-4 8-11v-52c0-8-4-12-13-12h-4v75zm-25-91h23c19 0 27 10 27 29v48c0 20-8 29-26 29h-24zm63 25c0-17 9-27 25-27s24 9 24 27v10h-16v-11c0-8-3-11-8-11-6 0-9 3-9 11v59c0 7 3 11 9 11 5 0 8-4 8-11v-17h-10v-13h26v53h-8l-3-7c-4 7-9 10-16 10-14 0-22-11-22-30zm64-25h43v16h-26v27h18v16h-18v31h26v16h-43zM112 381H95V275h24c18 0 26 8 26 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm0-60v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm53-46l10 42h1l10-42h17l-19 62v44h-16v-42l-20-64zm69 90c6 0 9-4 9-11v-51c0-9-4-13-13-13h-4v75zm-24-90h23c18 0 27 9 27 29v47c0 20-8 30-26 30h-24zm136 46v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm-72-46h43v15h-26v28h19v15h-19v32h26v16h-43zm72 106h-16V275h24c17 0 25 8 25 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm57-30c1 11 4 16 11 16 6 0 10-4 10-10 0-9-6-14-15-23-12-12-20-21-20-35 0-15 11-26 25-26s24 10 25 26l-16 2c-1-9-3-13-9-13-5 0-9 4-9 10 0 9 7 16 17 25 11 11 18 19 18 34s-11 26-26 26-26-11-27-29zm-65-172v-42h-42a42 42 0 1142 42z' fill='orangered' fill-rule='evenodd'/%3E%3C/svg%3E")
        no-repeat 0 center;
    }
  }
  &.active,
  &.project,
  &.page,
  &.news {
    @apply text-black bg-white;
    border-bottom: 0.5px solid #efefef;
    .home_menu {
      background: rgba(0, 0, 0, 0);
      a {
        text-shadow: none;
      }
    }
    .links {
      height: 100%;
      a.scrollactive-item {
        @apply border-l;
        color: black;
        border-color: #e5e7eb;
        &:hover {
          @apply underline;
        }
        &.is-active {
          background: #fafafa;
        }
      }
    }
  }
}

.mobile_menu {
  display: none;
}

.menu {
  .tag {
    background: white;
    @apply ml-1 bg-gray-100 inline-flex border;
    white-space: nowrap;
    border-radius: 100px;
    @apply text-base leading-tight tracking-tight;
    padding: 6px 12px !important;
    font-size: 12px;
    img {
      height: 18px;
      margin-right: 5px;
    }
  }
}

@keyframes showMenu {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.buttons {
  @apply flex mb-4;
  a {
    @apply rounded-full font-bold border px-4 py-3 mr-2;
    &:first-child {
      background: black;
      color: white;
    }
  }
}

h2.title {
  width: 65%;
  min-width: 300px;
  @apply font-bold text-3xl leading-tight mb-3;
}
.mobile_menu {
  display: none;
  @apply border-t fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  z-index: 9999;
  left: 0;
  top: 0px;
  background: #fafafa;
  color: black;
  &.active {
    display: block;
    animation: showMenu 0.45s ease forwards;
  }
  &.close {
    animation: showMenu 0.45s ease reverse forwards;
  }
  .mobile_menu_container {
    margin: 60px auto 20px;
    width: 85%;
  }
  .mobile_links {
    display: flex;
    width: 100%;
    flex-direction: column;
    .mobile-nav-item {
      @apply font-normal;
      text-transform: capitalize;
    }
    a {
      @apply p-4 px-0 border-b border-gray-200 font-normal text-lg;
      text-transform: capitalize;
    }
    a:last-child {
      border: none;
    }
    h3 {
      @apply text-xl font-bold mt-4 border-b mb-2 px-0 pb-3;
      background-repeat: no-repeat !important;
    }
  }
  .social {
    a {
      padding-left: 40px !important;
      background-size: 25px !important;
      background-position: 0 center !important;
      background-repeat: no-repeat !important;
      &.twitter {
        background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fab' data-icon='twitter' class='svg-inline--fa fa-twitter fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M459 152l1 13c0 139-106 299-299 299-59 0-115-17-161-47a217 217 0 00156-44c-47-1-85-31-98-72l19 1c10 0 19-1 28-3-48-10-84-52-84-103v-2c14 8 30 13 47 14A105 105 0 0136 67c51 64 129 106 216 110-2-8-2-16-2-24a105 105 0 01181-72c24-4 47-13 67-25-8 24-25 45-46 58 21-3 41-8 60-17-14 21-32 40-53 55z'/%3E%3C/svg%3E");
        background-size: 25px !important;
      }
      &.facebook {
        background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fab' data-icon='facebook' class='svg-inline--fa fa-facebook fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M504 256a248 248 0 10-287 245V328h-63v-72h63v-55c0-62 37-96 94-96 27 0 55 5 55 5v61h-31c-31 0-40 19-40 38v47h69l-11 72h-58v173a248 248 0 00209-245z'/%3E%3C/svg%3E");
      }
      &.linkedin {
        background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fab' data-icon='linkedin-in' class='svg-inline--fa fa-linkedin-in fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='currentColor' d='M100 448H7V149h93zM54 108C24 108 0 84 0 54a54 54 0 01108 0c0 29-25 54-54 54zm394 340h-93V302c0-34 0-79-48-79s-56 38-56 77v148h-93V149h90v41h1c12-24 43-49 88-49 94 0 111 62 111 143v164z'/%3E%3C/svg%3E");
        background-size: 25px !important;
      }
      &.email {
        background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='envelope' class='svg-inline--fa fa-envelope fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M502 191c4-3 10 0 10 5v204c0 27-21 48-48 48H48c-26 0-48-21-48-48V196c0-5 6-8 10-5l154 114c21 15 56 47 92 47s72-33 92-47l154-114zM256 320c23 0 57-29 73-41 133-97 143-105 174-129 6-5 9-12 9-19v-19c0-26-22-48-48-48H48C22 64 0 86 0 112v19c0 7 3 14 9 19 31 24 41 32 174 129 16 12 50 41 73 41z'/%3E%3C/svg%3E");
        background-size: 25px !important;
      }
    }
  }
}

$height: 50px;
$trs-time: 0.3s;

.mobile_menu_icon {
  width: 25px;
  height: 30px;
  margin-top: 10px;
  margin-right: 20px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  display: none;
  z-index: 999999;
  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: 0px;
    }
    &:nth-child(2) {
      top: 9px;
    }
    &:nth-child(3) {
      top: 9px;
    }
    &:nth-child(4) {
      top: 18px;
    }
  }
  &.active {
    span {
      background: #000;
      &:nth-child(1) {
        top: 9px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 9px;
        width: 0%;
        left: 50%;
      }
    }
  }
}

.menu.active {
  color: black;
  a.logo {
    background: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 250a250 250 0 11500 0 250 250 0 01-500 0zm97-103v106h42v-16h-26v-31h19v-16h-19v-27h26v-16zm80 91c6 0 8-4 8-11v-52c0-8-4-12-13-12h-4v75zm-25-91h23c19 0 27 10 27 29v48c0 20-8 29-26 29h-24zm63 25c0-17 9-27 25-27s24 9 24 27v10h-16v-11c0-8-3-11-8-11-6 0-9 3-9 11v59c0 7 3 11 9 11 5 0 8-4 8-11v-17h-10v-13h26v53h-8l-3-7c-4 7-9 10-16 10-14 0-22-11-22-30zm64-25h43v16h-26v27h18v16h-18v31h26v16h-43zM112 381H95V275h24c18 0 26 8 26 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm0-60v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm53-46l10 42h1l10-42h17l-19 62v44h-16v-42l-20-64zm69 90c6 0 9-4 9-11v-51c0-9-4-13-13-13h-4v75zm-24-90h23c18 0 27 9 27 29v47c0 20-8 30-26 30h-24zm136 46v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm-72-46h43v15h-26v28h19v15h-19v32h26v16h-43zm72 106h-16V275h24c17 0 25 8 25 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm57-30c1 11 4 16 11 16 6 0 10-4 10-10 0-9-6-14-15-23-12-12-20-21-20-35 0-15 11-26 25-26s24 10 25 26l-16 2c-1-9-3-13-9-13-5 0-9 4-9 10 0 9 7 16 17 25 11 11 18 19 18 34s-11 26-26 26-26-11-27-29zm-65-172v-42h-42a42 42 0 1142 42z' fill='orangered' fill-rule='evenodd'/%3E%3C/svg%3E")
      no-repeat 0 center;
  }
  .title {
    span.home {
      color: orangered;
    }
  }
  .mobile_menu_icon {
    span {
      background: black;
    }
  }
}

@media only screen and (max-width: 600px) {
  .mobile_menu_icon {
    display: block !important;
  }
  .menu.image .title span.home {
    color: black;
  }
  .menu {
    @apply px-3;
    @apply text-black bg-white;
    border-bottom: none;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
    &.image a.logo,
    a.logo {
      background: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 250a250 250 0 11500 0 250 250 0 01-500 0zm97-103v106h42v-16h-26v-31h19v-16h-19v-27h26v-16zm80 91c6 0 8-4 8-11v-52c0-8-4-12-13-12h-4v75zm-25-91h23c19 0 27 10 27 29v48c0 20-8 29-26 29h-24zm63 25c0-17 9-27 25-27s24 9 24 27v10h-16v-11c0-8-3-11-8-11-6 0-9 3-9 11v59c0 7 3 11 9 11 5 0 8-4 8-11v-17h-10v-13h26v53h-8l-3-7c-4 7-9 10-16 10-14 0-22-11-22-30zm64-25h43v16h-26v27h18v16h-18v31h26v16h-43zM112 381H95V275h24c18 0 26 8 26 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm0-60v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm53-46l10 42h1l10-42h17l-19 62v44h-16v-42l-20-64zm69 90c6 0 9-4 9-11v-51c0-9-4-13-13-13h-4v75zm-24-90h23c18 0 27 9 27 29v47c0 20-8 30-26 30h-24zm136 46v-31h6c8 0 10 3 10 15 0 11-2 16-10 16zm-72-46h43v15h-26v28h19v15h-19v32h26v16h-43zm72 106h-16V275h24c17 0 25 8 25 30 0 13-3 21-10 26l12 50h-17l-10-45h-8zm57-30c1 11 4 16 11 16 6 0 10-4 10-10 0-9-6-14-15-23-12-12-20-21-20-35 0-15 11-26 25-26s24 10 25 26l-16 2c-1-9-3-13-9-13-5 0-9 4-9 10 0 9 7 16 17 25 11 11 18 19 18 34s-11 26-26 26-26-11-27-29zm-65-172v-42h-42a42 42 0 1142 42z' fill='orangered' fill-rule='evenodd'/%3E%3C/svg%3E")
        no-repeat 0 center;
    }
    .links {
      display: none;
    }
    .home_menu {
      display: none;
    }
    &.image {
      background: white !important;
      a {
        color: black !important;
      }
    }
  }
}

.share {
  @apply flex text-base items-center mr-2 inline-block;
  &:hover {
    cursor: pointer;
  }
  a {
    width: 30px !important;
    padding: 0 !important;
    height: 30px !important;
    @apply inline-block rounded-full mr-2;

    &.twitter {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='244.2' width='300'%3E%3Cpath d='M95 243A173 173 0 00269 61c11-8 22-19 30-31-11 5-23 8-35 9 13-7 22-19 27-34-12 7-25 13-39 15a61 61 0 00-104 56C97 74 52 49 22 12a61 61 0 0019 82c-10 0-20-3-28-8v1c0 30 21 54 49 60a61 61 0 01-28 1c8 24 31 42 58 42a123 123 0 01-91 26c27 17 59 27 94 27' fill='%23fff'/%3E%3C/svg%3E")
        #1da1f2 no-repeat;
      background-size: 50% !important;
      background-position: center 55% !important;
    }
    &.facebook {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fab' data-icon='facebook-f' class='svg-inline--fa fa-facebook-f fa-w-10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23fff' d='M279 288l14-93h-89v-60c0-25 13-50 53-50h40V6s-37-6-72-6c-73 0-121 44-121 125v70H23v93h81v224h100V288z'/%3E%3C/svg%3E")
        #4767aa no-repeat;
      background-size: 29% !important;
      background-position: 48% 52% !important;
    }
    &.linkedin {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fab' data-icon='linkedin-in' class='svg-inline--fa fa-linkedin-in fa-w-14' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath fill='white' d='M100 448H7V149h93zM54 108C24 108 0 84 0 54a54 54 0 01108 0c0 29-25 54-54 54zm394 340h-93V302c0-34 0-79-48-79s-56 38-56 77v148h-93V149h90v41h1c12-24 43-49 88-49 94 0 111 62 111 143v164z'/%3E%3C/svg%3E")
        RGBA(21, 105, 191, 1) no-repeat;
      background-size: 34% !important;
      background-position: 48% 52% !important;
    }
    &.email {
      background: url("data:image/svg+xml,%3Csvg aria-hidden='true' data-prefix='fas' data-icon='at' class='svg-inline--fa fa-at fa-w-16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' stroke='black' stroke-width='20' d='M256 8a248 248 0 10135 456c12-8 15-25 6-36l-10-12c-8-9-21-12-32-5A184 184 0 0172 256c0-101 83-184 184-184 100 0 184 58 184 160 0 39-21 80-58 84-18-1-17-13-14-30l24-121c3-15-9-29-24-29h-45a14 14 0 00-13 12c-15-18-41-22-60-22-75 0-138 63-138 152 0 65 37 106 96 106 27 0 57-16 75-39 10 34 41 34 71 34 109 0 150-71 150-147C504 96 394 8 256 8zm-22 304c-22 0-36-15-36-40 0-45 31-73 59-73 22 0 35 15 35 41 0 45-33 72-58 72z'/%3E%3C/svg%3E")
        no-repeat #000;
      background-size: 50% !important;
      background-position: center 55% !important;
    }
  }
}
</style>
